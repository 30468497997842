import axios from 'axios';
// import authHeader from './header';
var authHeader = {
  Authorization: `Bearer LAbfPEqJYE-I6Cl-CyXxgzGA`,
};
//const API = "https://api.tahocrm.ru/";
// const API = 'https://apidev.tahocrm.ru/';
// const API = 'http://localhost:8000/';

// const IMG = 'https://apidev.tahocrm.ru/uploads/';
// const API = 'http://109.194.2.11:3000/';

const IMG = process.env.VUE_APP_API_IMG_SERV;
const API = process.env.VUE_APP_API_API_SERV;


class UserService {
  GET(url, data) {
    return axios.get(API + url, { params: data, headers: authHeader });
  }
  POST(url, formData) {
    return axios.post(API + url, formData, { headers: authHeader });
  }
}
UserService.prototype.URL = API;
UserService.prototype.IMG = IMG;
export default new UserService();
