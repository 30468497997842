<template>
  <div class="flex" style="flex-flow: column">
    <label v-if="labelInput" class="labelForInput text-gray-400" for="">{{
      labelInput
    }}</label>
    <select
      class="selectInput px-3 py-2"
      :class="{ labeledInput: classLabeled == true }"
      :value="modelValue"
      @change="updateSelect"
    >
      <option
        v-for="option in options"
        :key="option.id"
        :disabled="option.disabled"
        :selected="option.selected"
        :value="option.id"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SelectUI',
  props: {
    modelValue: String,
    options: Array,
    classLabeled: Boolean,
    labelInput: String,
  },
  emits: { changeValue: null, 'update:modelValue': null },
  methods: {
    updateSelect(event) {
      this.$emit('update:modelValue', event.target.value);
      this.$emit('changeValue', event.target.value);
    },
  },
};
</script>

<style>
.selectInput {
  background: #fff;
  border-radius: 2px;
  font-size: 1rem;
  line-height: 130%;
}
.labeledInput {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  font-size: 1rem;
  line-height: 130%;
  color: rgb(15 118 110);
  background-color: rgb(248 250 252);
}
</style>
