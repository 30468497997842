import Input from './Input.vue';
import Checkbox from './Checkbox.vue';
import Button from './Button.vue';
import Pagination from './Pagination.vue';
import Calendar from './Calendar.vue';
import Select from './Select.vue';
import File from './File.vue';
import Toast from './Toast.vue';

export default [
  Input,
  Checkbox,
  Button,
  Pagination,
  Calendar,
  Select,
  File,
  Toast,
];
