<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import MainLayout from '@/shared/layouts/MainLayout.vue';
import AuthLayout from '@/shared/layouts/AuthLayout.vue';
import { useToast } from 'vue-toastification';

export default {
  name: 'App',
  components: { MainLayout, AuthLayout },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'main') + '-layout';
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
};
</script>

<style>
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.page-bg {
  background-color: #e2e8f0;
  display: flex;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}
.wrap-page {
  width: 100%;
  padding: 15px;
  max-height: 100vh;
  overflow-y: auto;
}
body {
  margin: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-long-enter-active,
.fade-long-leave-active {
  transition: opacity 1s ease;
}

.fade-long-enter-from,
.fade-long-leave-to {
  opacity: 0;
}



.mobile-only{
  display: none;
}
.mobile-menu{
  display: none;
}
.mobile-block{
  display: none;
}
.pc-flex {
  display: flex;
}

@media(min-width: 769px){

  #top-tabs {
    align-items: flex-end;
  }
  #top-tabs > button{
    height: 50px;
    transition: 0.5s ease;
    padding-top: 0px;
    transform:translateY(15px);
  }
  #top-tabs > button:nth-child(1){
    border-top-left-radius: 10px;
  }
  #top-tabs > button:nth-child(2){
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
  #top-tabs > button:nth-child(3){
    border-top-right-radius: 10px;
  }
  .choosen-button{
    transform: translateY(0px) !important;
    padding-top: 10px !important;
    border-top-right-radius: 10px!important;
    border-top-left-radius: 10px!important;
  }
  .wrap-orders{
    z-index: 1;
    position: relative;
  }
}
.mobile-grid {
  display: none;
}
.mobile-flex1 {
  display: none;
}
.mobile-block {
  display: none; 
}
.mobile-table {
  display: none;
}
@media(max-width: 768px){
  .date-start-text {
    font-size: 0.65rem!important;
    display: flex;
  }
  .text-number {
    font-size: 12px;
  }
  .mobile-table {
    display: inline-table;
  }
  .page-bg {
    height: calc(100vh - 96px);
  }
  .wrap-page {
    height: calc(100vh - 96px);
  }
  .mobile-block {
    display: block; 
  }
  .mobile-grid {
    display: grid;
  }
  .mobile-flex1 {
    display: flex;
  }
  .pc-flex {
    display: none!important;
  }
  .mobile-none {
    display: none!important;
  }
  .mobile-menu{
    display: flex;
    height: 95px;
    background: #033848;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 10px 20px 16px 20px;
  }
  .mobile-menu > a{
    display: flex;
    align-items: center;
    color: #fff;
    line-height: 100%;
    width: 50%;
    justify-content: center;
    margin: 7px 0;
  }
  .mobile-menu > a > svg{
    width: 20px;
    height: 20px;
    fill: #fff;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  
  .mobile-none{
    display: none;
  }
  .mobile-block{
    display: block;
  }
  .menu-section{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    transition: 0.5s ease;
    transform:translateX(-100%);
  }
  .h1-agregator{
    width: 100%;
    text-align: center;

    color: #000;
    font-size: 24px;
    font-weight: 700;
  }
  .text-to-show{
    color: #000;
    font-size: 9px!important;
    line-height: 120%!important;
    font-style: normal;
    font-weight: 700;
    line-height: normal; 
  }
  #top-tabs{
    align-items: flex-end;
  }
  #top-tabs > button{
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 33.3%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    height: 40px;
    transition: 0.5s ease;
  }
  #top-tabs > button:nth-child(1){
    border-top-left-radius: 5px;
  }
  #top-tabs > button:nth-child(2){
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
  #top-tabs > button:nth-child(3){
    border-top-right-radius: 5px;
  }

  .choosen-button{
    height: 50px!important;
    border-top-right-radius: 5px!important;
    border-top-left-radius: 5px!important;
  }
  .order-id-text{
    color: #000;
    text-align: center;
    font-size: 10px!important;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
  }

  .mobile-a-first-row{
    flex-flow: column;
  }
  .from-who-mobile{
    width: 100%!important;
    margin-right: 0;
  }
  .mobile-label-date{
    font-size: 12px!important;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
  }
  .labeledInput{
    font-size: 14px!important;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
  }
  .input-date-a-mobile{
    width: 35%!important;
  }
  .button-plus-mobile-a{
    margin-top: 15px;
    padding: 8px 15px !important;
    text-align: center;
  }
}
</style>
