<template>
  <transition name="toast-animate">
    <section v-if="visible" :class="style ? style : 'toast success'">
      <p class="toast-message">{{ message }}</p>
      <button @click="$emit('close')">X</button>
    </section>
  </transition>
</template>

<script>
export default {
  name: 'ToastUI',
  props: {
    visible: Boolean,
    style: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.toast {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 2rem;
  right: 2rem;
  max-width: 450px;
  border-radius: 0.5rem;
  padding: 1rem;
  z-index: 1000;
}

.toast-message {
  font-family: 'latoreg';
  font-size: 18px;
  font-weight: 500;
}

.success {
  background: #c6f6d5;
  color: #225439;
}

.info {
  background: #bee3f8;
  color: #2a4365;
}

.warning {
  background: #fefcbf;
  color: #744210;
}

.danger {
  background: #fed7d7;
  color: #742a2a;
}

.toast-animate-enter-active {
  transition: all 0.3s ease;
}
.toast-animate-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.toast-animate-enter,
.toast-animate-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
