import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  concat,
} from '@apollo/client/core';

const API_PROTOCOL = process.env.VUE_APP_API_PROTOCOL;
const API_URL = process.env.VUE_APP_API_URL;
const API_PORT = process.env.VUE_APP_API_PORT;

export function createApolloClient() {
  const httpLink = createHttpLink({
    // uri: `${API_PROTOCOL}://${API_URL}:${API_PORT}`,
    uri: `${API_PROTOCOL}://${API_URL}`,
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('token');
    const userID = localStorage.getItem('userID');
    const userRoles = localStorage.getItem('userRoles');

    operation.setContext({
      headers: {
        Authorization: token ? token : '',
        UserID: userID ? userID : '',
        userRoles: userRoles ? userRoles : '',
      },
    });
    return forward(operation);
  });

  const cache = new InMemoryCache();

  const apolloClient = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache,
    defaultOptions: {
      fetchPolicy: 'network-only',
      watchQuery: {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
      },
    },
  });

  return apolloClient;
}
