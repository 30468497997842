<template>
  <div class="flex" style="flex-flow: column">
    <label v-if="labelInput" class="labelForInput text-gray-400" for="">{{
      labelInput
    }}</label>
    <input
      v-if="disabled == true"
      :value="modelValue"
      disabled
      :class="{ labeledInput: classLabeled == true }"
      class="input px-3 py-2"
      @input="updateInput"
    />
    <input
      v-else
      :value="modelValue"
      :class="{ labeledInput: classLabeled == true }"
      class="input px-3 py-2"
      @input="updateInput"
    />
  </div>
</template>

<script>
export default {
  name: 'InputUI',
  props: {
    labelInput: String,
    modelValue: [String, Number],
    classLabeled: Boolean,
    disabled: Boolean,
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style>
.label-For-Input {
  font-size: 0.8rem;
  line-height: 1;
}
.input {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  font-size: 1rem;
  line-height: 130%;
}
.labeledInput {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  font-size: 1rem;
  line-height: 130%;
  color: rgb(15 118 110);
  background-color: rgb(248 250 252);
}
</style>
