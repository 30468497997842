import { createApp, h, provide } from 'vue';
import {
  DefaultApolloClient,
  provideApolloClient,
} from '@vue/apollo-composable';
import { createPinia } from 'pinia';
import App from './App.vue';
import store from '@/store/index';
import uiComponents from '@/shared/components/UI';
import router from './router';
import { createApolloClient } from '../createApolloServer.js';
import vClickOutside from "click-outside-vue3"
import './assets/tailwind.css';

import Toast from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

provideApolloClient(createApolloClient());

const app = createApp({
  setup() {
    provide(DefaultApolloClient, createApolloClient());
  },
  render: () => h(App),
});

uiComponents.forEach((component) => {
  app.component(component.name, component);
});

const options = {
  transition: 'Vue-Toastification__fade',
  pauseOnFocusLoss: false,
  timeout: 5000,
  showCloseButtonOnHover: true,
};

app.use(vClickOutside);
app.use(Toast, options);
app.use(store);
app.use(createPinia());

app.use(router);
app.mount('#app');
