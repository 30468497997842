<template>
  <input
    :value="modelValue"
    type="date"
    min="1918-01-01"
    max="2118-12-31"
    class="dateFilter px-3 py-2"
    :name="name"
    :placeholder="placeholder"
    @input="updateCalendar"
  />
</template>

<script>
export default {
  name: 'CalendarUI',
  props: {
    modelValue: Date,
  },
  emits: { dateChange: null },
  data() {
    return {
      placeholder: 'дд.мм.гггг',
      name: 'dateTo',
      date: '',
    };
  },
  methods: {
    updateCalendar(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style>
.dateFilter {
  border-radius: 5px;
  font-size: 1rem;
  line-height: 130%;
}
</style>
