<template>
  <div class="flex page-bg">
    <div class="wrap-page">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
};
</script>

<style>
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.page-bg {
  background-color: #e2e8f0;
  display: flex;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}
.wrap-page {
  width: 100%;
  padding: 15px;
  max-height: 100vh;
  overflow-y: auto;
}
body {
  margin: 0;
}
</style>
