<template>
  <div class="mobile-menu">
    <router-link
      :to="'/calendar'"
    >
    <svg><use xlink:href="@/assets/i.svg#nav-calendar"></use></svg>
      Календарь
    </router-link>
    <router-link
      :to="'/agregator'"
    >
    <svg><use xlink:href="@/assets/i.svg#nav-orders"></use></svg>
      Агрегатор
    </router-link>

    <router-link
      :to="'/agrorders'"
    >
    <svg><use xlink:href="@/assets/i.svg#nav-flow"></use></svg>
      Мои заказы
    </router-link>
    
    <router-link
      :to="'/profile'"
    >
    <svg><use xlink:href="@/assets/i.svg#ico-avatar"></use></svg>
      Профиль
    </router-link>

  </div>
  <div class="flex page-bg">
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    />
    <Menu @click="closeMenu()" :class="{'calling-section': callMenu == true}"/>
    
    <div class="wrap-page">
      <router-view />
    </div>
  </div>
</template>

<script>
import Menu from '@/shared/components/menu/Menu.vue';

export default {
  name: 'MainLayout',
  components: {
    Menu,
  },
  data() {
    return {
      callMenu: false,
      showedMenu: false,
      location: '',
    };
  },
  methods: {
    closeMenu(){
      console.log(this.showedMenu);
      if(this.showedMenu == true){
        this.callMenu = false;
        this.showedMenu = false;
      }
    }
  },
  // .bg-filter-green {
  //   fill: #3cc45a;
  // }
  mounted () {
    this.location = window.location.pathname;
  }
};
</script>

<style scoped>
  .router-link-exact-active {
    color: #3cc45a!important;
  }
  .router-link-exact-active > svg > use {
    fill: #3cc45a!important;
  }
  
</style>

<style>
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.page-bg {
  background-color: #e2e8f0;
  display: flex;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}
.wrap-page {
  width: 100%;
  padding: 15px;
  max-height: 100vh;
  overflow-y: auto;
}
body {
  margin: 0;
}

@media(max-width: 768px){
  .wrap-page {
    padding: 0px!important;
  }
}
</style>
