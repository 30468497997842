import storage from '@/shared/utils/localstorage';
import { createRouter, createWebHistory } from 'vue-router';
import { getActivePinia } from 'pinia';

const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: 'profile',
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Авторизация',
      requiresAuth: false,
      layout: 'auth',
    },
    component: () => import('@/pages/Login.vue'),
  },
  {
    path: '/agregator',
    name: 'agregator',
    meta: {
      title: 'Агрегатор',
      requiresAuth: true,
    },
    component: () => import('@/pages/AgregatorPage.vue'),
  },
  {
    path: '/agrorders',
    name: 'agrorders',
    meta: {
      title: 'Агрегатор',
      requiresAuth: true,
    },
    component: () => import('@/pages/MyAgregatorPage.vue'),
  },
  {
    path: '/order-form/:id',
    name: 'order-form',
    meta: {
      title: 'Форма заказа',
      requiresAuth: true,
    },
    component: () => import('@/pages/OrderForm.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      title: 'Профиль',
      requiresAuth: true,
    },
    component: () => import('@/pages/Profile.vue'),
  },
  {
    path: '/orders',
    name: 'orders',
    meta: {
      title: 'Заказы',
      requiresAuth: true,
    },
    component: () => import('@/pages/OrdersPage.vue'),
  },
  {
    path: '/services',
    name: 'services',
    meta: {
      title: 'Услуги',
      requiresAuth: true,
    },
    component: () => import('@/pages/ServicesPage.vue'),
  },
  {
    path: '/clients',
    name: 'clients',
    meta: {
      title: 'Клиенты',
      requiresAuth: true,
    },
    component: () => import('@/pages/ClientsPage.vue'),
  },
  {
    path: '/agrpack/:id',
    name: 'agrpack',
    meta: {
      title: 'Заявка на заказ',
      requiresAuth: true,
    },
    component: () => import('@/pages/AgregatorPack.vue'),
  },
  {
    path: '/agrorder/:id',
    name: 'agrorder',
    meta: {
      title: 'Заказ',
      requiresAuth: true,
    },
    component: () => import('@/pages/AgregatorOrder.vue'),
  },
  
  
  {
    path: '/client/:id',
    name: 'client',
    meta: {
      title: 'Клиент',
      requiresAuth: true,
    },
    component: () => import('@/pages/ClientPage.vue'),
    children: [
      {
        path: 'info',
        name: 'infoTab',
        component: () => import('@/pages/ClientTabs/infoTab.vue'),
        requiresAuth: true,
      },
      {
        path: 'companys',
        name: 'companysTab',
        component: () => import('@/pages/ClientTabs/companyTab.vue'),
        requiresAuth: true,
      },
      {
        path: 'contacts',
        name: 'contactsTab',
        component: () => import('@/pages/ClientTabs/contactsTab.vue'),
        requiresAuth: true,
      },
      {
        path: 'ts',
        name: 'tsTab',

        component: () => import('@/pages/ClientTabs/tsTab.vue'),
        requiresAuth: true,
      },
      {
        path: 'order',
        name: 'orderTab',

        component: () => import('@/pages/ClientTabs/orderTab.vue'),
        requiresAuth: true,
      },
      {
        path: 'activations',
        name: 'activationsTab',

        component: () => import('@/pages/ClientTabs/activationsTab.vue'),
        requiresAuth: true,
      },
      {
        path: 'reclamakciiTab',
        name: 'reclamakciiTab',

        component: () => import('@/pages/ClientTabs/reclamakciiTab.vue'),
        requiresAuth: true,
      },
      {
        path: 'tasks',
        name: 'tasksTab',

        component: () => import('@/pages/ClientTabs/taskTab.vue'),
        requiresAuth: true,
      },
    ],
  },
  {
    path: '/tasks',
    name: 'tasks',
    meta: {
      title: 'Задачи',
      requiresAuth: true,
    },
    component: () => import('@/pages/Tasks.vue'),
  },
  {
    path: '/calendar',
    name: 'calendar',
    meta: {
      title: 'Календарь',
      requiresAuth: true,
    },
    component: () => import('@/pages/CalendarPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (storage('token') != undefined || storage('token') != null) {
    var master, logist, manager, admin;
    var flag = false;

    if (storage('userRoles').includes('master') == true) {
      master = true;
    }
    if (storage('userRoles').includes('manager') == true) {
      manager = true;
    }
    if (storage('userRoles').includes('logist') == true) {
      logist = true;
    }
    if (storage('userRoles').includes('admin') == true) {
      admin = true;
    }
    // alert(manager);

    if (manager) {
      if (
        to.name === 'login' ||
        // to.name === 'clients' ||
        // to.name === 'client' ||
        // to.name === 'infoTab' ||
        // to.name === 'companysTab' ||
        // to.name === 'contactsTab' ||
        // to.name === 'orderTab' ||
        // to.name === 'activationsTab' ||
        // to.name === 'reclamakciiTab' ||
        // to.name === 'tasksTab' ||
        // to.name === 'tsTab' ||
        // to.name === 'tasks' ||
        // to.name === 'orders' ||
        // to.name === 'order-form' ||
        // to.name === 'profile' ||
        to.name === 'agregator' ||
        to.name === 'agrpack' ||
        to.name === 'agrorders' ||
        to.name === 'agrorder'
      ) {
        flag = true;
      } else {
        flag = false;
      }
    }

    if (logist) {
      flag = true;
    }

    if (admin) {
      flag = true;
    }

    if (master || logist || admin) {
      if (
        to.name === 'login'     ||
        to.name === 'agregator' ||
        // to.name === 'orders' ||
        // to.name === 'order-form' ||
        to.name === 'calendar'  ||
        to.name === 'profile'   ||
        to.name === 'agrpack'   ||
        to.name === 'agrorders' ||
        to.name === 'agrorder'
      ) {
        flag = true;
      } else {
        flag = false;
      }
    }

    if (to.name === 'login') {
      flag = true;
    }
    if (flag == false) {
      next('/profile');
    }
  }
  next();
});

router.beforeEach((to, from, next) => {
  to.matched.some((record) => record.meta.requiresAuth)
    ? storage('token') &&
      (storage('token') != undefined || storage('token') != null)
      ? next()
      : next('/login')
    : next();
});

router.beforeEach((to, from, next) => {
  if (
    to.name === 'login' &&
    storage('token') &&
    (storage('token') != undefined || storage('token') != null)
  ) {
    localStorage.clear();
    next('/profile');
  }
  next();
});

export default router;
