<template>
  <section class="menu-section pt-8">
    <img :src="getImage(userAvatarka)" alt="Аватарка пользователя" />

    <p
      style="
        color: rgb(34 197 94);
        margin-top: 30px;
        margin-bottom: 50px;
        text-align: center;
        width: 100%;
      "
      class="menu-link-text font-semibold"
    >
      {{ nameOfUser }}
    </p>

    <div class="flex flex-col justify-space-between menu-height-wrap">
      <ul class="menu-links">
        <MenuLink
          v-for="menuLink in menuLinks"
          :key="menuLink.id"
          :linkInfo="menuLink"
        />
      </ul>
      <ButtonUI @click.prevent="goToTaho()" class="bg-white mx-4 mb-4 mt-auto"
      ><span class="text-black font-bold" style="display: block; margin-top: 5px;">В CRM</span></ButtonUI
    >
      <p class="menu-brand-text text-xs text-[#e5e7eb] block">
        © TahoCRM 2023
      </p>
    </div>
  </section>
</template>

<script>
import MenuLink from './MenuLink.vue';
import { menuLinks } from '@/mocks/menu/menuLinks';
import storage from '@/shared/utils/localstorage';

export default {
  name: 'MenuComponent',
  components: {
    MenuLink,
  },
  data() {
    return {
      menuLinks,
      userAvatarka: '',
      nameOfUser: '',
    };
  },
  async mounted() {
    if (storage('token') != null && storage('token') != undefined) {
      this.userAvatarka = storage('userAvatar');
      for (let i = 0; i < this.menuLinks.length; i++) {
        this.menuLinks[i].show = false;
      }

      if (storage('userRoles').includes('master')) {
        // this.menuLinks[0].show = true;
        this.menuLinks[3].show = true;
        this.menuLinks[4].show = true;
        this.menuLinks[5].show = true;
      }
      if (storage('userRoles').includes('manager')) {
        // this.menuLinks[0].show = true;
        // this.menuLinks[1].show = true;
        this.menuLinks[4].show = true;
        this.menuLinks[5].show = true;
      }

      if (storage('userRoles').includes('logist')) {
        for (let i = 0; i < this.menuLinks.length; i++) {
          // this.menuLinks[i].show = true;
          this.menuLinks[3].show = true;
          this.menuLinks[4].show = true;
          this.menuLinks[5].show = true;
        }
      }
      if (storage('userRoles').includes('admin')) {
        for (let i = 0; i < this.menuLinks.length; i++) {
          // this.menuLinks[i].show = true;
          this.menuLinks[3].show = true;
          this.menuLinks[4].show = true;
          this.menuLinks[5].show = true;
        }
      }

      // this.menuLinks[4].show = false;

      this.nameOfUser = storage('userName');
      if (storage('userRoles').includes('master')) {
        this.roleOfUser = 'Инженер';
      }
      if (storage('userRoles').includes('manager')) {
        if (this.roleOfUser == '') {
          this.roleOfUser += 'Менеджер';
        } else {
          this.roleOfUser += ', Менеджер';
        }
      }
      if (storage('userRoles').includes('logist')) {
        if (this.roleOfUser == '') {
          this.roleOfUser += 'Логист';
        } else {
          this.roleOfUser += ', Логист';
        }
      }
      if (storage('userRoles').includes('admin')) {
        if (this.roleOfUser == '') {
          this.roleOfUser += 'Администратор';
        } else {
          this.roleOfUser += ', Администратор';
        }
      }
    }
  },
  methods: {
    goToTaho() {
      window.open('https://tahocrm.ru/');
    },
    getImage(avatar) {
      try {
        return require(`@/assets/images/${avatar}.jpg`);
      } catch {
        return require(`@/assets/images/admiavatar.jpg`);
      }
    },
  },
};
</script>

<style>
.menu-section {
  background-image: url('@/assets/images/bg-leftbar.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  min-width: 280px;
  max-width: 280px;
  height: 100vh;
  overflow-y: auto;
}

.menu-section > img {
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 1000px;
}

.menu-brand-text {
  text-align: center;
  width: 100%;
}
.menu-links {
  padding: 0;
  width: 100%;
}
.user-role {
  text-align: center;
  font-weight: 600;
}
.menu-height-wrap {
  min-height: calc(100vh - 300px);
}
</style>
