<template>
  <button :class="style ?? 'button arduinopx-3 py-2'">
    <span v-if="text">{{ text }}</span>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonUI',
  props: {
    style: String,
    text: String,
  },
};
</script>

<style scoped>
.button {
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 8px 15px;
  border: none;
  cursor: pointer;
  color: #fff;
  transition: 0.3s ease;
}

.seconadry-button {
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 8px 15px;
  border: none;
  cursor: pointer;
  background-color: #6b7280;
  color: #fff;
  transition: 0.3s ease;
}

.button:hover {
  opacity: 0.7;
}
</style>
